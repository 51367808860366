import React, { useEffect } from "react";

import {
    SEO,
} from "@components";


export default function SlackPage() {
    useEffect(() => {
        window.location.replace("https://docs.google.com/spreadsheets/d/14GUYT7N3grkfNhW6EtgReCsYT1VRLS8hcfU_uifF0bg/edit#gid=1853964725");
    });

    return (
        <main>
            <SEO title="Sorteios #reactconfbr22" />
        </main>
    );
}
